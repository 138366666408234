<template>
  <div v-loading="loading" class="table-area">
    <el-row class="table-top">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trader Fund</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper"></div>
      </el-col>
    </el-row>

    <div>
      <el-card>
        <div class="table_wrapper">
          <table class="table" border="1" width="100%">
            <tbody>
              <tr>
                <th>
                  <p>User Name:</p>
                </th>
                <td>
                  <p>{{ viewData.username }}</p>
                </td>
                <th>
                  <p>Name:</p>
                </th>
                <td>
                  <p>{{ viewData.name }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Amount :</p>
                </th>
                <td>
                  <p>{{ viewData.amount }}</p>
                </td>
                <th>
                  <p>Id :</p>
                </th>
                <td>
                  <p>{{ viewData.fund_id }}</p>
                </td>
              </tr>
              <tr>
                <th>
                  <p>Created At :</p>
                </th>
                <td>
                  <p>{{ viewData.created_at }}</p>
                </td>
                <th>
                  <p>Modified At :</p>
                </th>
                <td>
                  <p>{{ viewData.updated_at }}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { show } from "@/api/trader-fund";

export default {
  name: "PendingOrders",
  components: {},
  data() {
    return {
      loading: false,
      viewData: {},
    };
  },
  computed: {},
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.getPendingOrderDetails();
  },
  methods: {
    // //////////////////
    // GET LIST DATA
    // //////////////////
    handlePagination() {
      this.getPendingOrderDetails();
    },
    handleSearch() {
      this.listQuery.page = 1;
      this.getPendingOrderDetails();
    },
    getPendingOrderDetails() {
      this.loading = true;
      show(this.$route.params.id).then((response) => {
        this.viewData = response.data.data;
        console.log(this.viewData);
        this.loading = false;
      });
    },
  },
};
</script>
