import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: '/funds',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: `funds/view/`,
    method: 'get',
    params: { id }
  })
}

export function store(data) {
  return request({
    url: '/funds/store',
    method: 'post',
    data
  })
}

export function debit(data) {
  return request({
    url: '/funds/debit',
    method: 'post',
    data
  })
}

export function edit(id) {
  return request({
    url: `funds/${id}`,
    method: 'get'
  })
}

export function update(id, data) {
  return request({
    url: `funds/${id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `funds/${id}`,
    method: 'delete'
  })
}
export function fundExport(query) {
  return request({
    url: `funds/export`,
    method: 'get',
    params: query
  })
}



